<template>
  <div class="col-lg-12">
    <div class="card border-1 border-left-3 border-left-primary text-center mb-lg-0">
      <div class="card-body">
        <b-row no-gutters>
          <b-col
            md="5 mb-4"
            class="rounded bg-secondary"
            style="box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.2); opacity: 0.85"
          >
            <img
              :src="resource.static ? getImgUrl(resource.image_url) : resource.image_url"
              class="img-responsive"
              height="125"
              style="marging-bottom: 100px !important"
            />
          </b-col>
          <b-col md="7">
            <h3>{{ resource.title }}</h3>
            <div>{{ resource.short_description }}</div>
            <a
              v-if="resource.external_link && !resource.enbl_detail_pg"
              class="btn btn-primary setup-guide-btn btn-normal mt-3"
              style="width: 220px"
              :href="getClickableLink(resource.external_link)"
              target="_blank"
              >{{ resource.cta_btn_text }}
            </a>
            <router-link
              v-else
              class="btn btn-primary setup-guide-btn btn-normal mt-3"
              style="width: 220px"
              :to="
                resource.enbl_detail_pg && !resource.static
                  ? { name: 'resource-detail', params: { slug: resource.slug } }
                  : { name: this.resource.pageRoute }
              "
              >{{ resource.cta_btn_text }}</router-link
            >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getClickableLink } from '@/common/utils';

export default {
  name: 'Resource',
  props: {
    resource: { type: Object, default: null },
  },

  methods: {
    getClickableLink,

    getImgUrl(pic) {
      return require('@/assets/images/placeholders/' + pic);
    },
  },
};
</script>

<style>
</style>